/* LoginPage.css */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  .login-form input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
  }
  
  .login-form button {
    padding: 10px;
    background-color: blue;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .login-form button:hover {
    background-color: darkblue;
  }
  
  .forgot-password {
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    color: blue;
  }
  