.dashboard {
  display: flex;
  height: 100vh;
  position: relative;
}

.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  overflow-y: auto;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.sidebar-item {
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
  transition: background-color 0.3s;
}

.sidebar-item:hover {
  background-color: #ddd;
}

.content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto; /* Allows vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow-y: auto;
}

.tabs {
  display: flex;
  margin-bottom: 10px;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tab.active {
  border-bottom: 2px solid blue;
}

.upload-button, .logout-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

.actions {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.accept-button, .reject-button {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.accept-button {
  background-color: green;
}

.close-sidebar {
  display: none;
}

.reject-button {
  background-color: red;
}

.text-card {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-card p {
  white-space: normal; /* Allows text to wrap */
  word-wrap: break-word; /* Ensures long words don't overflow */
}

.diagnoses {
  max-height: 200px;
}

.timeline-card {
  margin-bottom: 20px;
}

.timeline-card strong {
  font-weight: bold;
}

.timeline-card p {
  margin-left: 20px;
}

textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.hamburger-button {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    width: 70%;
    height: 100%;
    z-index: 99;
    left: 0;
    top: 0;
    transform: translateX(-100%);

  .sidebar.open .close-sidebar {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .content {
    width: 100%;
  }
}

.logout-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto; /* Adjusts width to content */
}

.map-card {
  height: auto;
}